/**  =====================
      Custom css start
==========================  **/
// @font-face {
//     font-family: 'Open Sans';
//     src: url(path/to/file) format(Example: 'truetype' or 'opentype' depending on the file extension of your font);
// }

@font-face {
  font-family: "Arciform";
  src: local("Arciform"),
    url(../../../font/Arciform/Arciform.otf) format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: local("Lato"),
    url(../../../font/Montserrat/Montserrat-Regular.ttf) format("opentype");
}

body {
  // font-family: $theme-font-family;
  font-family: Arciform;
  font-size: $theme-font-size;
  color: $theme-font-color;
  font-weight: 400;
  background: $theme-background;
  position: relative;
}

* {
  &:focus {
    outline: none;
  }
}

a {
  font-family: Montserrat;

  &:hover {
    outline: none;
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-heading-color;
  font-weight: 400;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 44px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

p {
  font-family: Montserrat;
  font-size: 14px;
}

label,
select,
button,
span,
input,
th,
td,
li,
textarea,
h6 {
  font-family: Montserrat;
}

.td-block {
  display: block;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

@media only screen and (min-width: 500px) {
  .td-block {
    display: table-cell;
  }
}

@media only screen and (max-width: 500px) {

  /* horizontal scrollbar for tables if mobile screen */
  .td-block {
    overflow-x: auto;
    display: block;
  }
}

strong {
  font-weight: 400;
}

.img-radius {
  border-radius: 50%;
}

.page-header-title+.breadcrumb {
  background: transparent;
  padding: 0;

  >.breadcrumb-item {
    a {
      color: #888;
    }

    &:last-child a {
      color: #111;
      font-weight: 600;
    }
  }
}

.theme-bg {
  background: $theme-color;
}

.theme-bg2 {
  background: $theme-color2;
}

/* ==========  card css start  =========== */

.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.anim-close-card {
  animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

p.text-muted {
  font-size: 13px;
}

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  transition: all 0.5s ease-in-out;

  .card-header {
    background-color: transparent;
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 25px;
    position: relative;

    +.card-block,
    +.card-body {}

    h5 {
      margin-bottom: 0;
      color: #000;
      font-size: 17px;
      font-weight: 400;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.1;
      position: relative;

      &:after {
        content: "";
        background-color: $primary-color;
        position: absolute;
        left: -25px;
        top: 0;
        width: 4px;
        height: 20px;
        display: none;
      }
    }

    &.borderless {
      border-bottom: none;

      h5:after {
        display: none;
      }
    }

    .card-header-right {
      right: 10px;
      top: 10px;
      display: inline-block;
      float: right;
      padding: 0;
      position: absolute;

      @media only screen and (max-width: 575px) {
        display: none;
      }

      .dropdown-menu {
        margin-top: 0;

        li {
          a {
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }

      .btn.dropdown-toggle {
        border: none;
        background: transparent;
        box-shadow: none;
        color: #888;

        i {
          margin-right: 0;
        }

        &:after {
          display: none;
        }

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
    }
  }

  .card-footer {
    border-top: 1px solid #f1f1f1;
    background: transparent;
    padding: 25px;
  }

  .card-block,
  .card-body {
    padding: 30px 25px;
  }

  &.card-load {
    position: relative;
    overflow: hidden;

    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(256, 256, 256, 0.7);
      z-index: 999;

      i {
        margin: 0 auto;
        color: $primary-color;
        font-size: 24px;
        align-items: center;
        display: flex;
      }
    }
  }

  &.full-card {
    z-index: 99999;
    border-radius: 0;
  }
}

/* ==========  scrollbar End  =========== */

.scroll-y {
  z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */

.dropdown-menu {
  padding: 20px 0;
  margin-top: 15px;
  box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none;

  &.show {
    &:before {
      content: "\63";
      font-family: "pct";
      position: absolute;
      left: 15px;
      top: -5px;
      z-index: 1001;
      font-size: 40px;
      line-height: 0;
      color: #fff;
      text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
    }

    &.dropdown-menu-right {
      &:before {
        left: auto;
        right: 5px;
      }
    }
  }

  &[x-placement="top-start"] {
    margin-bottom: 15px;
    margin-top: 0;

    &.show {
      &:before {
        content: "\64";
        bottom: -5px;
        top: auto;
        text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
      }
    }
  }

  >li {
    padding-left: 15px;
    padding-right: 15px;

    >a {
      padding: 5px;
      color: $theme-font-color;

      i {
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      background: transparentize($primary-color, 0.9);

      >a {
        background: transparent;
      }
    }
  }
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

/* ================================   Custom Landing date Start  ===================== */
.date-container {
  position: relative;
  max-width: "200px" ;
}

.input-icon-align {
  position: relative;
  width: 100%;
}

.date-input {
  padding-right: 2.5rem; /* Espacio para el icono */
  margin-bottom: 1px !important;
}

.calendar-icon {
  position: absolute;
  right: 10px; /* Ajustado para mayor proximidad */
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  color: rgb(78, 106, 197);
  padding: 6px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.calendar-icon:hover {
  background-color: rgb(78, 106, 197);
  color: #fff;
}

.date-text {
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: rgb(78, 106, 197);
  text-transform: uppercase; 
  margin-top: 5px; 
}

/* ================================    Custom Landing date  Start  ===================== */

/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/

.bd-example-row {
  background: $theme-background;
  padding: 15px;
  margin-bottom: 15px;

  .row {

    >[class^="col-"],
    >.col {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      background-color: rgba(86, 61, 124, 0.15);
      border: 1px solid rgba(86, 61, 124, 0.2);
    }
  }

  .row+.row {
    margin-top: 1rem;
  }

  .flex-items-bottom,
  .flex-items-middle,
  .flex-items-top {
    min-height: 6rem;
    background-color: rgba(255, 0, 0, 0.1);
  }
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

/* Docs examples */

.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;

  @media only screen and (max-height: 575px) {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

/* Example modals */

.modal {
  z-index: 1072;

  .popover,
  .tooltip {
    z-index: 1073;
  }
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;

  .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }

  .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

/* Example tabbable tabs */

.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

/* Code snippets */

.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media only screen and (max-height: 575px) {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;

  @media only screen and (max-height: 575px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight {
  pre {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: 0;
  }

  pre code {
    font-size: inherit;
    color: #333;
  }
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */

/* Code examples */
.datta-example {
  position: relative;

  .datta-example-btns {
    position: absolute;
    right: 30px;
    top: -10px;
    opacity: 0;
    transform: rotateX(-90deg);
    transition: all 0.3s ease-in-out;

    .datta-example-btn {
      display: inline-block;
      line-height: 1;
      font-weight: 600;
      background: $primary-color;
      color: #fff;
      padding: 0.1875rem 0.3125rem;
      border-radius: 2px;
      white-space: nowrap;
      font-size: 11px;
      margin: 0 4px;

      &.copy::before {
        content: "COPY";
      }

      &.copied {
        background: $success-color !important;
        color: #fff !important;

        &::before {
          content: "COPIED!";
        }
      }
    }
  }

  &:hover .datta-example-btns {
    top: -21px;
    transform: rotateX(0deg);
    opacity: 1;
  }
}

// custom Modal for Mapdot details Starts

/* Controla el ancho máximo y evita scroll horizontal */
.responsive-modal {
  max-width: 95vw; /* nunca excede el viewport */
  width: 100%;
  margin: auto;
}

.responsive-modal .modal-content {
  max-height: 90vh; /* para que no exceda en alto tampoco */
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}




// custom Modal for Mapdot details Ends

/* Modal */

.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  transform-origin: 50% 0;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
  overflow: hidden !important;

  .datta-example-modal {
    transform: scale(1);
  }
}

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto;

  >pre {
    overflow: hidden;
    width: fit-content;

    >code {
      padding: 0;
      background: none;
      font-size: 16px;
    }
  }
}

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: $primary-color;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  &.copied::before {
    content: "Copied to Clipboard Successfully ! . . .";
    position: absolute;
    display: block;
    right: 100%;
    margin-right: 10px;
    font-size: 14px;
    background: $success-color;
    line-height: 24px;
    height: 24px;
    border-radius: 3px;
    padding: 0 6px;
    top: 50%;
    margin-top: -12px;
  }
}

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  transition: all 0.3s ease-in-out;
  z-index: 1;

  &:hover {
    color: #fff;
    opacity: 0.9;
  }
}

/* Code */
.cui-bottom-spacer {
  height: 12rem;
}

/* editor style for model */
.hljs {
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

/* ================================    md animated mode start   ===================== */

.md-show.md-effect-12~.md-overlay {
  background: $theme-color;
}

/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */

.tooltip {
  &.in {
    opacity: 0.9;
  }

  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }

  &.top {
    padding: 5px 0;
    margin-top: -3px;

    .tooltip-arrow {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      border-width: 5px 5px 0;
      border-top-color: #000;
    }
  }
}

/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */

.owl-carousel {
  .owl-nav {
    button {

      &.owl-next,
      &.owl-prev {
        padding: 3px 9px;
      }
    }
  }

  button.owl-dot {}
}

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */

.bootstrap-tagsinput {
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 2px;
  background: $form-bg;

  .tag {
    padding: 5px 12px;
    border-radius: 2px;
    line-height: 37px;
    margin-top: 5px;
    margin-right: 5px;

    [data-role="remove"] {
      margin-right: -5px;

      &:after {
        content: "\e8f6";
        padding: 0 2px;
        font-family: "feather" !important;
      }
    }
  }
}

/* ================================    custom table mapDots details Start  ===================== */
.img-size {
  width: 100%;
  height: 20rem;
  object-fit: cover;
}

@media only screen and (max-width: 425px) {
  .tr-df {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .td-btop-0 {
    border-top: none !important;
    padding: 0 !important;
  }

  .img-size {
    width: 100%;
    height: 10rem;
  }
}

/* ================================    custom table mapDots details End  ===================== */
/* ================================    custom Upload image Start  ===================== */
.formImg-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imgPreviewContainer {
  display: flex;
  padding: 10px;
  flex: 1 1 100vw;
  flex-flow: row wrap;
  justify-content: center;
}

.previewImgItem {
  display: flex;
  margin: 10px;
  padding: 10px;
  flex: 0 0 350px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.imgPreview {
  max-width: 150px;
  height: 150px;
}

.detailsImg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.imgupload {
  color: #1E2832;
  padding-top: 40px;
  font-size: 5em;
}

#fileup {
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  width: 200px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  height: 50px;
}

.detailsImg h6 {
  font-size: 10pt;
  font-weight: normal;
  color: #999;
  padding: 5px;
  margin: 0;
}

.detailsImg i {
  cursor: pointer;
  color: red;
}

.dragDropImg {
  position: relative;
  margin-top: 10px;
  height: 275px;
  background: hsla(0, 0%, 100%, 0.25);
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #ddd;
  border: 5px dashed #eee;
}

.draggingImg {
  background-color: #fff;
  color: lightsteelblue;
}

.insideImg {
  margin-top: 35px;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 425px) {
  .uploadImgInput input {
    max-width: 20em;
  }

  .previewImgItem {
    max-width: 16.5em;
  }
}

/* ================================    custom Upload image End ===================== */

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */

.ms-container {
  width: 100%;
}

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */

.blockquote {
  border-left: 0.25rem solid $theme-border;
  padding: 0.5rem 1rem;

  &.text-right {
    border-left: none;
    border-right: 0.25rem solid $theme-border;
  }
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */

.card .animated {
  animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;

  p {
    font-size: 17px;
  }

  h1 {
    color: #fff;
  }

  .iew-container {
    min-width: 1024px;
    width: 100%;
    height: 200px;
    background: #fff;
    margin: 50px 0;
  }

  .iew-download {
    list-style: none;
    padding: 30px 0;
    margin: 0 auto;
    width: 720px;

    >li {
      float: left;
      vertical-align: top;

      >a {
        display: block;
        color: #000;
        width: 140px;
        font-size: 15px;
        padding: 15px 0;

        >div {
          margin-top: 10px;
        }

        &:hover {
          background-color: #eee;
        }
      }
    }
  }
}

/* ================================    browser  warning  End  ===================== */

/* ================================ custom chat align starts ===================== */
.otherUserButtons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

/* ================================ custom chat align ends ===================== */

/* material icon for material datetime picker */
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* material icon for material datetime picker */

/* ------ Custom only for landing Starts  ----- */

/* ==========================================================================
   Hero Section
   ========================================================================== */
.landing-section-custom {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40.5em !important;
  width: 100%;
  background-position: 50% 60%;
}

// @media only screen and (max-height: 575px) {
//   landing-section-custom {
//     height: 35em !important;
//   }
// }

.app-landing-content-custom {
  overflow: hidden;
  padding: 1px 0 0 0;
}

.hero-content-custom {
  width: 100%;
  padding: 0 0 10px 0;
  margin-top: 1vh;
}

.hero-content-custom h1 {
  font-size: 64px;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  letter-spacing: -2px;
  margin-bottom: 25px;
}

.hero-content-custom p {
  font-size: 14px;
}

.hero-section-landing {
  background-image: url("../../images/landing/landing.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: cover !important;
  background-attachment: scroll;
  width: 100%;
  height: 45vh !important;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.hero-section-landing:before {
  background: linear-gradient(153.5deg,
      #54aa7c 0%,
      #4e6ac5 44.75%,
      #c870c8 100%);
  content: "";
  width: 100%;
  height: 45vh !important;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.8;
  z-index: -1;
}

.hero-section-landing:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5vh !important;
  left: 0;
  bottom: 0;
  background-color: white;
  border-top-left-radius: 50px;
  z-index: -1;
}

.hero-content-landing h1 {
  margin-bottom: 10px;
  font-size: 40px !important;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 25px;
  color: #fff;
  letter-spacing: 1.5px;
}

.hero-content-landing p {
  color: #fff;
  line-height: 1.5;
  margin: 0 0 25px;
}

.hero-content-landing .download-btn:hover {
  opacity: 0.8;
}

.hero-inner-landing {
  position: relative;
}

.hero-moc {
  background-image: url("../../images/landing/marceIcon.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  width: 500px;
  height: 600px;
  vertical-align: middle;
  bottom: -160px;
  right: 0;
}

.promo-content-landing i {
  background-image: linear-gradient(153.5deg,
      #54aa7c 0%,
      #4e6ac5 44.75%,
      #c870c8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 50px;
  padding: 5px;
}

.download-section-landing {
  position: relative;
  overflow: hidden;
  background: linear-gradient(153.5deg,
      #54aa7c 0%,
      #4e6ac5 44.75%,
      #c870c8 100%);
  left: 0;
  top: 0;
  width: 100%;
  height: 40.5em !important;
  padding: 100px 0;
  opacity: 0.8;
}

// @media only screen and (max-height: 575) {
//   .download-section-landing {
//     height: 35em !important;
//   }
// }

.download-section-landing h2 {
  color: #fff;
}

.download-content-landing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-bottom: 65px;
  padding-top: 0;
}

@media (min-width: 1600px) {
  .download-content-landing {
    align-items: flex-start;
    max-width: 1540px;
  }
}

@media (max-width: 992px) {
  .download-content-landing h2 {
    // margin: 0 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
}

.button-group-landing {
  margin: 0 15px !important;
}

.button-group-landing a:first-child {
  margin-bottom: 10px;
}

.moc-download img {
  position: absolute;
  top: -10em;
  width: 40em;
  left: 100% !important;
}

@media (min-width: 1600px) {
  .moc-download img {
    left: 45% !important;
    max-width: 1540px;
  }
}

@media (max-width: 992px) {
  .moc-download img {
    left: 55% !important;
    max-width: 100%;
    transform: rotate(14deg);
  }
}

@media (max-width: 767px) {
  .moc-download img {
    display: none !important;
    max-width: 100%;
  }
}


.buttons-flex-landing img {
  padding: 10px;
  width: 13em;
  height: 5em;

  @media only screen and (min-height: 480px) {
    padding: 5px;
    width: 10em;
    height: 4em;
  }
}

.buttons-flex-landing {
  display: flex;
  flex-direction: column; /* Pone el texto arriba y las imágenes debajo */
  align-items: center; /* Centra el contenido horizontalmente */
  padding: 5em;
}

.text-container {
  text-align: center; /* Centra el texto */
  margin-bottom: 20px; /* Espacio entre el texto y las imágenes */
  color: #fff;
}

.buttons-container {
  display: flex;
  justify-content: center; /* Centra las imágenes horizontalmente */
  gap: 20px; /* Espacio entre las imágenes */
}

.buttons-possition-inline {
  display: flex;
}


.buttons-flex-landing img {
  padding: 10px;
  width: 13em;
  height: 5em;

  @media only screen and (min-height: 480px) {
    padding: 5px;
    width: 10em;
    height: 4em;
  }
}

.header-text-landing p {
  margin: 0 0 5px !important;
}

.bailo-background {
  -webkit-flex-direction: row;
  flex-direction: row;
  background: linear-gradient(153.5deg,
      #54aa7c 0%,
      #4e6ac5 44.75%,
      #c870c8 100%) !important;
}

.bailo-background-inverse {
  -webkit-flex-direction: row;
  flex-direction: row;
  background: linear-gradient(-153.5deg,
      #54aa7c 0%,
      #4e6ac5 44.75%,
      #c870c8 100%) !important;
}

.p-w p,
h2 {
  color: #fff !important;
}

.h2-w h2 {
  color: #fff !important;
}

.link-w a {
  color: #bec9d1 !important;
}

.responsive-navbar-nav-custom {
  background: none;
  border: none !important;
}

/* ------ Custom only for landing Ends  ----- */
/* ------ Custom for login and sign up Starts  ----- */

.toggleButton {
  background: #e7f1fe;
  color: #4e6ac5;
  border: 1px solid #ced4da;
}

/* ------ Custom for login and sign up Ends  ----- */
/* ------ Custom for laguages Starts -------*/
.language-options {
  color: #4e6ac5;
  background-color: transparent;
  background-image: none;
  border-color: #4e6ac5;
  border-radius: 0.25rem;
  margin: 0 5px;
}

/* ------ Customo for laguages Ends -------*/
/* ------ Custom banner Starts -------*/
#slider {
  height: 200px;
  overflow: hidden;
  width: 100%;
  color: #888;
}

/* ------ Custom banner Starts -------*/
/* ------ Custom datepiker Starts -------*/
input[type="date"] {
  display: block;
  position: relative;
  padding: 10px 20px;
  margin: 0 10px 5px 0;

  font-size: 1rem;
  color: #4e6ac5;
  background-color: transparent;
  height: 43px;
  font-size: 14px;
  font-family: Montserrat;
  border: 1px solid #4e6ac5;
  border-radius: 0.25rem;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E") right 1rem center no-repeat;

  cursor: pointer;
}

::-webkit-datetime-edit-text {
  opacity: 0;
}

::-webkit-clear-button,
::-webkit-inner-spin-button {
  display: none;
}

::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  cursor: pointer;

  background: #4e6ac5;
  color: #fff;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator {
  background: #4e6ac5;
  color: #fff;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator:hover {
  background: #4e6ac5 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='55' height='52' viewBox='0 0 20 22'><g fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'><rect width='18' height='18' y='2' rx='2'/><path d='M13 0L13 4M5 0L5 4M0 8L18 8'/></g></svg>");
}

/* ------ Custom datepiker Starts -------*/
/* ------ Custom Landing banner starts -------- */


.spotify-embed {
  background: transparent;
  width: 85%;

  iframe {
    width: 100%;
  }
}

.caption {
  position: absolute;
  bottom: 5px;
  left: 20px;
  opacity: 0.0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}


.card-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.card-top {
  height: 33%;
  overflow-y: auto;
}

.card-bottom {
  height: 33%;
  overflow-y: auto;
}

/* ------ custom Landing banner ends -------- */

/* --------- custom Landing Contenedor padre del Map and list view ends ------- */
/* ContentMap container: altura total de pantalla */
.content-container {
  height: 100vh;
  overflow: hidden;
}

/* Row que ocupa todo el alto del padre */
.content-map-row {
  height: 100%;
}

/* Columna izquierda (MapVenuList o BailoMap) */
.map-section {
  height: 100%;
  padding: 20px;
}

/* Contenedor interno donde va MapVenuList o BailoMap */
.map-content {
  width: 100%;
  height: 85%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  background-color: #fff;
  overflow-y: auto;
  padding: 1px;
}

/* Columna derecha (DownloadAppBanner) */
.banner-section {
  height: 100%;
  background-color: #ffffff;
  padding: 20px;
  overflow: hidden;
}
/* --------- custom Landing Contenedor padre del Map and list view ends ------- */

/* --------- custom Landing Map and list view starts ------- */
/* Clase para el contenedor */
.mapdot-container {
  width: 50vw; /* Ancho en pantallas grandes (no responsive) */
  height: 85%;
  overflow-y: auto;
  padding: 10px;
}

/* Media query para pantallas pequeñas */
@media (max-width: 767px) {
  .mapdot-container {
    width: 76vw; /* Ancho en pantallas pequeñas (responsive) */
  }
}

/* --------- custom Landing Map and list view ends ------- */
